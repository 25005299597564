import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from '../styles/staticPages.js';
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function TermCondition(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{margin:'-8px'}}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/header-back.jpg").default} />
      <div className={classNames(classes.main, classes.mainRaised)}>
 
      <div className={classes.container} style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>
            <br/>
            <h2  style={{textAlign:isRTL === 'rtl'?'right':'left',position: "relative",marginTop: "30px",minHeight: "32px",color: "#383838",textDecoration: "none",[isRTL === "rtl" ? "marginLeft" : "marginRight"]: "20px",wordBreak: "break-word"}}>{t('term_condition')}</h2>
            {/* <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>{t('term_condition_para1')}</p>
            <br/> */}
            {/* <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading1')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para2')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading2')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para3')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading3')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para4')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading4')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para5')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading5')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para6')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading6')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para7')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading7')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para8')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading8')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para9')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading9')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para10')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading10')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para11')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading11')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para12')}
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
            <strong>{t('term_condition_heading12')}</strong>
            </p>
            <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
                {t('term_condition_para13')}
          </p> */}
          <p className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}><p><strong>Terms and Conditions for Velocity Parcel Delivery Application</strong></p>
            <p>These Terms and Conditions ("Terms," "Agreement") outline the terms of use governing the Velocity Parcel Delivery mobile application ("App") provided by Velocity Drop Limited ("Velocity Drop," "we," "us," or "our"). By accessing or using our App, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using our App.</p>
            <p><strong>1. User Account and Registration:</strong></p>
            <p><strong>a. Account Creation:</strong></p> <p>To access certain features of the App, Users need to register and create an account. You agree to provide accurate, current, and complete information during the registration process and promptly update this information as needed.</p>
            <p><strong>b. Account Security:</strong></p> <p>You are responsible for maintaining the security and confidentiality of your account credentials. You agree not to share your account details or allow others to access your account.</p>
            <p><strong>2. Use of the App:</strong></p>
            <p><strong>a. License:</strong></p> <p>Subject to these Terms, Velocity Drop grants you a limited, non-exclusive, non-transferable, and revocable license to use the App for personal or commercial purposes in accordance with its intended use.</p>
            <p><strong>b. Prohibited Activities:</strong></p> <p>Users agree not to engage in any unlawful, abusive, or unauthorized use of the App. This includes but is not limited to unauthorized access, interference with the App's functionality, or any action that may disrupt the service.</p>
            <p><strong>3. Services Offered:</strong></p>
            <p><strong>a. Parcel Delivery Services:</strong></p> <p>The App facilitates the arrangement of delivery services between Users and independent delivery providers. Velocity Drop is not a delivery service provider but acts as an intermediary to facilitate connections between Users and delivery partners.</p>
            <p><strong>b. Service Availability:</strong></p> <p>While we aim to provide uninterrupted services, we do not guarantee the availability of the App at all times and reserve the right to modify or discontinue services without notice.</p>
            <p><strong>4. Orders, Payments, and Deliveries:</strong></p>
            <p><strong>a. Order Placement:</strong></p> <p>Users may place delivery orders through the App by providing accurate parcel details, pick-up, and delivery information.</p>
            <p><strong>b. Payment Processing:</strong></p> <p>Users agree to pay for delivery services as specified in the App. Payment processing may be conducted through a secure third-party payment gateway.</p>
            <p><strong>c. Delivery Timelines:</strong></p> <p>While we endeavor to meet delivery timelines, actual delivery times may vary due to factors beyond our control, and we cannot guarantee specific delivery times.</p>
            <p><strong>5. Intellectual Property Rights:</strong></p>
            <p><strong>a. Ownership:</strong></p><p> All intellectual property rights related to the App, including trademarks, copyrights, and proprietary content, are owned by Velocity Drop Limited or its licensors.</p>
            <p><strong>b. License Limitations:</strong></p> <p>Users agree not to reproduce, modify, distribute, or create derivative works based on the App without prior written consent from Velocity Drop Limited.</p>
            <p><strong>6. User Responsibilities:</strong></p>
            <p><strong>a. Compliance:</strong></p><p> Users agree to comply with all applicable laws, regulations, and these Terms while using the App.</p>
            <p><strong>b. Accuracy of Information:</strong></p> <p>Users are responsible for the accuracy of the information provided, including parcel details, delivery addresses, and contact information.</p>
            <p><strong>7. Privacy Policy:</strong></p>
            <p><strong>a. Privacy:</strong></p><p> Use of the App is subject to our Privacy Policy. By using the App, you consent to the collection, processing, and sharing of your information as described in the Privacy Policy.</p>
            <p><strong>8. Limitation of Liability:</strong></p>
            <p><strong>a. Disclaimer:</strong></p> <p>The App is provided "as is" without warranties of any kind, and Velocity Drop Limited disclaims all warranties, express or implied, regarding the App's accuracy, reliability, or suitability for a particular purpose.</p>
            <p><strong>b. Limitation of Liability:</strong></p> <p>Velocity Drop Limited shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from the use or inability to use the App or the services provided.</p>
            <p><strong>9. Termination and Suspension:</strong></p>
            <p><strong>a. Termination:</strong></p><p> Velocity Drop Limited reserves the right to suspend or terminate access to the App at its discretion without prior notice, especially in cases of suspected misuse or violation of these Terms.</p>
            <p><strong>10. Amendments and Modifications:</strong></p>
            <p><strong>a. Changes to Terms:</strong></p><p> Velocity Drop Limited reserves the right to modify, update, or revise these Terms at any time. Users may be notified of material changes, and continued use of the App constitutes acceptance of the revised Terms.</p>
            <p><strong>11. Governing Law and Dispute Resolution:</strong></p>
            <p><strong>a. Governing Law:</strong></p> <p>These Terms shall be governed by and construed in accordance with the laws of Kenya, without regard to its conflict of law provisions.</p>
            <p><strong>b. Dispute Resolution:</strong></p><p> Any disputes arising out of or relating to these Terms shall be settled through arbitration in Nairobi, Kenya, in accordance with the rules of arbitration in Kenya.</p>
            <p><strong>12. Contact Information:</strong></p>
            <p>If you have any questions or concerns regarding these Terms and Conditions or your use of the App, please contact us at:</p>
            <p><strong>Velocity Drop Limited</strong></p>
            </p>
          
          <p  className={isRTL === "rtl" ? classes.rtlDescription : classes.ltrDescription}>
          {t('term_condition_para14')} 
          {settings && settings.contact_email?
            <a href={"mailto:" + settings.contact_email}><strong>{settings.contact_email}</strong></a>
          :null}
          </p>
          <br/>
          <br/>
        </div>
        </div>
      <Footer/>
    </div>
  );
}

