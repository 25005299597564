export const FirebaseConfig = {
	"projectId": "velocity-delivery-app-e3aa7",
	"appId": "1:717836085995:web:ae584cfbeac9d264b0ecae",
	"databaseURL": "https://velocity-delivery-app-e3aa7-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "velocity-delivery-app-e3aa7.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDFqnW8kQ9wMFO_h2t9plba4aNuh8HowXA",
	"authDomain": "velocity-delivery-app-e3aa7.firebaseapp.com",
	"messagingSenderId": "717836085995",
	"measurementId": "G-4P35ZHS4QL"
};